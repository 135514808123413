// @flow strict
import React from "react";
import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as styles from "./Feed.module.scss";

const Feed = ({ edges }) => (
  <div className={styles.feed}>
    {edges.map((edge) => (
      <div className={styles.feed__item} key={edge.node.fields.slug}>
        <div className={styles.feed__itemMeta}>
          <time
            className={styles.feed__itemMetaTime}
            dateTime={new Date(edge.node.frontmatter.date).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          >
            {new Date(edge.node.frontmatter.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
          </time>
          <span className={styles.feed__itemMetaDivider} />
        </div>
        <h5 className={styles.feed__itemTitle}>
          <Link
            className={styles.feed__itemTitleLink}
            to={edge.node.fields.slug}
          >
            {edge.node.frontmatter.title}
          </Link>
        </h5>
        <div className={styles.feed__itemDescription}>
          <MDXRenderer>{edge.node.frontmatter.description}</MDXRenderer>
        </div>
        <Link className={styles.feed__itemReadmore} to={edge.node.fields.slug}>
          Read
        </Link>
      </div>
    ))}
  </div>
);

export default Feed;
