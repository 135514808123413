// @flow strict
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Feed from "../components/Feed";
import Page from "../components/Page";
import Pagination from "../components/Pagination";
import useSiteMetadata from "../utils/use-site-metadata";

const PostListTemplate = ({ data, pageContext }) => {
  const {
    author,
    title: siteTitle,
    subtitle: siteSubtitle,
  } = useSiteMetadata();

  const {
    pageNumber,
    humanPageNumber,
    previousPagePath,
    nextPagePath,
  } = pageContext;

  const { edges } = data.allMdx;
  const pageTitle =
    pageNumber > 0
      ? `Articles - Page ${humanPageNumber} - ${siteTitle}`
      : siteTitle;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page title="Last Articles">
        {/*<About />*/}
        <Feed edges={edges} />
        <Pagination
          previousPagePath={previousPagePath}
          nextPagePath={nextPagePath}
        />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($limit: Int!, $skip: Int!) {
    allMdx(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { template: { eq: "post" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
          }
        }
      }
    }
  }
`;

export default PostListTemplate;
